body {
  background-image: url('./background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.App {
  padding:20px;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  font-size: 12pt;
  /* font-size: calc(10px + 2vmin); */
  /* background-color:slategrey; */
  min-width:590px !important;
}

.App-link {
  color: #61dafb;
}

.App>.container {
  
  display:inline-block;
  margin:auto;
  margin-bottom: 0px !important;
}

.container {
  background-color: rgba(255, 255, 255, .8);
  border-radius: 5px;
  padding:10px;
  margin-top:10px !important;
  margin-bottom:10px !important;
  min-width: 840px !important;
  display: table;
}

.App input, .App textarea {
  width:100%;
  font-size:10pt;
}

textarea {
  height:75px;
}

.logo {
  position:relative;
  top:10px;
}

.header {
  margin-bottom: 20px !important;
  color: #0164CE;
}

h1 {
  font-weight:bold;
}

@media (min-width: 992px)
{
  h1 {
    font-size:40px !important;
  }
}

@media (min-width: 768px)
{
  h1 {
    font-size:40px !important;
    margin-left: 50px !important;
  }
}

@media (min-width: 576px)
{
  h1 {
    font-size:30px !important;
    margin-left: 100px !important;
  }
}

table {
  font-size: 10pt;
}

th {
  white-space: nowrap;
  margin: 0px 10px !important;
  padding-right: 12px;
}

.nowrap {
  white-space: nowrap;
}

.buttons {
  text-align: right;
}

button {
  margin: 0px 4px;
}

td {
  vertical-align: middle;
}

.logo {
  margin-bottom:20px;
  height:40px;
}

.spinner {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('./spinner.svg') 50% 50% no-repeat;
  background-size: 15vw 15vw;
  background-color:whitesmoke;
  opacity:.75;
}

.error {
  color:red;
  font-weight:bold;
}

.processing {
  color:rgba(16, 101, 169,0.9);
  font-weight:bold;
}

.footer-info {
  font-size: 8pt;
}

a {
  text-decoration: none;
  color:rgba(16, 101, 169,0.9);
}

a:hover {
  text-decoration: underline;
}

.copyright {
  margin-top: 2px !important;
  margin-bottom: 0px !important;
  padding: 0px;
  background-color: inherit;
  font-weight: bold;
  font-size: 8pt;
  text-align:right;
  opacity: .8;
}

.footer-container {
  margin-bottom: 0px !important;
}

.acknowledgement {
  margin-top:10px;
  
}

.acknowledgement span {
  font-size: 10pt;
}